import React from "react";
import { View } from "react-native";

import PackingsChip from "./PackingChip";


const Packings = (props) => {
    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
            {props.packingsList.map((el) => (
                <PackingsChip title={el.name} id={el.id} key={el.id} selected={props.packagingId === el.id} onPress={() => { props.selectedPackagingId(el.id) }}
                />
            ))}
        </View>
    );
}

export default Packings;
