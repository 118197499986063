
import { LOGIN, SET_DID_TRY_AL, LOGOUT } from '../actions/auth';

const initialState = {
    accountId: null,
    clientId: null,
    expirationDate: null,
    didTryAutoLogin: false,
    username: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                accountId: action.accountId,
                clientId: action.clientId,
                expirationDate: action.expirationDate,
                username: action.username
            };
        case LOGOUT:
            return {
                ...initialState,
                didTryAutoLogin: true
            };
        case SET_DID_TRY_AL:
            return {
                ...state,
                didTryAutoLogin: true
            };
        default:
            return state;
    }
};
