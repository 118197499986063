import React from "react";
import { View, Text, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';




const Products = ({ navigation }) => {
  const orders = useSelector(state => state.order.orders);
  const ordersReversed = orders.reverse();


  const listEmpty = () => {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: '70%' }}>
        <Text>
          Keine Bestellungen
        </Text>
      </View>
    );
  };




  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('HistoryOrder', {
        items: item.items,
        title: item.date.toString()
      })}>
        <View style={styles.listItem}>
          <View>
            <Text style={styles.itemTitle}>{item.date.toString()}</Text>
            <Text style={styles.itemText}>Produkte: {item.items.length}</Text>
          </View>
          <Ionicons name="ios-chevron-forward" size={24} color="black" />
        </View>
      </TouchableOpacity>)
  };
  return (
    <View style={{ width: '100%' }}>
      <FlatList
        data={orders}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        ListEmptyComponent={listEmpty}
      />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listItem: {
    padding: 15,
    borderBottomColor: 'grey',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10
  },
  itemText: {
    fontSize: 16
  }
});



export default Products;