import React, { useCallback, useState, useEffect } from "react";
import { View, Text, Alert, FlatList, StyleSheet, TouchableOpacity, ActivityIndicator, Platform } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons, Fontisto } from '@expo/vector-icons';

import * as productActions from '../store/actions/product';



const Item = ({ title }) => (
  <View style={(title == 'Hotlist') ? { borderBottomColor: 'lightgrey', borderBottomWidth: 1, marginBottom: 10, paddingBottom: 10 } : null}>
    <View style={styles.item}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={styles.title}>{title}</Text>
        {(title === 'Favoriten') ? <Ionicons name="ios-heart-sharp" size={24} color="red" /> : null}
        {(title === 'Hotlist') ? <Fontisto name={"fire"} size={24} color="orange" /> : null}
      </View>
      <Ionicons name="ios-chevron-forward" size={24} color="black" />
    </View>
  </View>
);



const ProductsGroups = ({ navigation }) => {
  const productGroups = useSelector(state => state.product.productGroups)
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();

  console.log(Platform.OS);

  useEffect(() => {
    if (productGroups.length < 1) {
      loadProducts();
    }
  }, []);


  const fetchProducts = async () => {
    try {
      await dispatch(productActions.fetchProducts())
    } catch (err) {
      Alert.alert('Fehler beim Verbinden', 'Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut.');
    }
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={() => navigation.navigate('ProductsGroup', { title: item.title })}>
      <Item title={item.title} />
    </TouchableOpacity>
  );

  const loadProducts = useCallback(() => {
    setIsRefreshing(true);
    fetchProducts();
    setIsRefreshing(false);
  })



  const listEmpty = () => {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: '70%' }}>
        <Text>
          Nach unten wischen um Artikel zu laden
        </Text>
      </View>
    );
  };


  if (!initialLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#00ff00" />
      </View>
    );
  }
  else if (initialLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <FlatList
          data={productGroups}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          style={styles.list}
          onRefresh={loadProducts}
          refreshing={isRefreshing}
          ListEmptyComponent={listEmpty}
        />
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 0,
  },
  item: {
    backgroundColor: 'lightgrey',
    padding: 10,
    marginVertical: 8,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    borderRadius: 12
  },
  title: {
    fontSize: 18,
    marginRight: 7
  },
  list: {
    width: "100%"
  }
});

export default ProductsGroups;