import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    TextInput,
    StatusBar,
    Alert,
    Text,
    TouchableOpacity,
    Modal,
    Switch
} from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import * as WebBrowser from 'expo-web-browser';

import { Colors } from '../constants/Colors';


const CustomModal = ({ modalVisible, setModalVisible }) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [items, setItems] = useState([
        { label: 'Apperl', value: 'apperl' },
        { label: 'Mohr', value: 'mohr' },
        { label: 'Niederwieser', value: 'niederwieser' },
        { label: 'Kitchenclub', value: 'kitchenclub' },
    ]);
    const [isEnabled, setIsEnabled] = useState(false);
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    const [result, setResult] = useState(null);

    const _handlePressButtonAsync = async () => {
        let result = await WebBrowser.openBrowserAsync('http://www.webbill.at/privacy.html');
        setResult(result);
    };


    let myBackground = {};
    if (isDisabled) {
        myBackground = { backgroundColor: 'lightgrey' };
    }


    useEffect(() => {
        if (firstname.length > 0 && lastname.length > 0 && email.length > 0 && value && isEnabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [firstname, lastname, email, value, isEnabled]);

    const registrationHandler = () => {
        Alert.alert('Registrierung abgeschickt', 'Sie erhalten in Kürze eine Bestätigung');
        setFirstname('');
        setLastname('');
        setEmail('');
        setValue(null)
        setModalVisible(false);
        setIsEnabled(false);
    };

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                Alert.alert("Modal has been closed.");
                setModalVisible(!modalVisible);
            }}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={styles.form}>
                        <Text style={styles.title}>Registrierung</Text>
                        <TextInput
                            style={styles.inputField}
                            value={firstname}
                            onChangeText={setFirstname}
                            autoCapitalize='words'
                            autoCorrect={false}
                            placeholder='Vorname'
                        />
                        <TextInput
                            style={styles.inputField}
                            value={lastname}
                            onChangeText={setLastname}
                            autoCapitalize='words'
                            autoCorrect={false}
                            placeholder='Vorname'
                        />
                        <TextInput
                            style={styles.inputField}
                            value={email}
                            onChangeText={setEmail}
                            autoCapitalize='none'
                            autoCorrect={false}
                            keyboardType='email-address'
                            placeholder='Email'
                        />
                        <DropDownPicker
                            open={open}
                            value={value}
                            items={items}
                            setOpen={setOpen}
                            setValue={setValue}
                            setItems={setItems}
                            style={{
                                borderRadius: 12,
                                height: 35,
                                marginBottom: 20
                            }}
                            placeholder="Lieferant auswählen"
                            placeholderStyle={{
                                color: "lightgrey",
                            }}
                        />
                        <View style={{ marginBottom: 20 }}>
                            <View style={{ flexDirection: 'row', marginBottom: 5 }}><Text style={{ color: 'blue' }} onPress={_handlePressButtonAsync}>Nutzungsbedingungen</Text><Text> akzeptieren</Text></View>

                            <Switch
                                onValueChange={toggleSwitch}
                                value={isEnabled}
                            />
                        </View>
                        <TouchableOpacity style={[styles.button, myBackground]} onPress={registrationHandler} disabled={isDisabled}>
                            <Text style={styles.buttonTitle}>Registrieren</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, { backgroundColor: 'grey' }]} onPress={() => { setModalVisible(false) }}>
                            <Text style={styles.buttonTitle}>Abbrechen</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        </Modal>

    );
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: StatusBar.currentHeight,
        backgroundColor: 'white'
    },
    form: {
        alignContent: 'center',
        top: -50,
        width: '70%',
        maxWidth: 300
    },
    inputField: {
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 12,
        height: 35,
        paddingHorizontal: 13,
        marginBottom: 15,
    },
    inputLabel: {
        marginLeft: 10,
    },
    button: {
        backgroundColor: Colors.primary,
        padding: 8,
        borderRadius: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 10,
        color: 'white'
    },
    logo: {
        width: 237.6,
        height: 159.6,
        top: -80
    },
    buildNumberContainer: {
        position: 'absolute',
        bottom: 20,
        right: 20
    },
    buildNumber: {
        color: 'grey'
    },
    link: {
        marginTop: 12,
        color: Colors.primary
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
        paddingHorizontal: 15
    },
    modalView: {
        flex: 1,
        width: '100%',
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        paddingTop: 100,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    title: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 20
    },
    container: {
        ...StyleSheet.absoluteFill
    }
});

export default CustomModal;
