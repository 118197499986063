//import { produce } from 'immer';
import { ORDER_CART, SET_ORDER_HISTORY } from '../actions/order';
import CartItem from '../../classes/cart-item';

const initialState = {
    orders: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDER_CART:
            const today = new Date();
            const newId = state.orders.length + 1;
            const newOrder = {
                id: today.getTime() / 1000,
                date: today.toLocaleDateString('de-DE',),
                items: action.cartItems
            }


            const updatedOrders = state.orders.slice(-30);
            //updatedOrders.sort((a, b) => (a.id > b.id) ? 1 : -1)
            updatedOrders.push(newOrder);
            return {
                ...state,
                orders: updatedOrders
            }
        case SET_ORDER_HISTORY:
            return {
                ...state,
                ...action.orderHistory
            }
        default:
            return state;
    }
};
