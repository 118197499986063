//import { produce } from 'immer';
import { ADD_ITEM_TO_CART, REMOVE_FROM_CART, UPDATE_ITEM_FORM_CART, EMPTY_CART, RESTORE_CART } from '../actions/cart';
import CartItem from '../../classes/cart-item';

const initialState = {
    items: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ITEM_TO_CART:
            const addedProduct = action.product;


            const updatedOrNEwCartItem = new CartItem(
                addedProduct.id,
                addedProduct.quantity,
                addedProduct.packagingId,
                addedProduct.name,
                addedProduct.packagingName,
                addedProduct.productNr,
                addedProduct.currentPrice
            );


            return {
                ...state,
                items: state.items.concat(updatedOrNEwCartItem)
            };
        case UPDATE_ITEM_FORM_CART:
            const updatedItem = action.product;
            const itemIndex = state.items.findIndex(item => item.id === updatedItem.id);

            const updateCartItem = new CartItem(
                updatedItem.id,
                updatedItem.quantity,
                updatedItem.packagingId,
                updatedItem.name,
                updatedItem.packagingName,
                updatedItem.productNr,
                updatedItem.currentPrice
            );

            const updatedCartItems = [...state.items];
            updatedCartItems[itemIndex] = updateCartItem;

            return {
                ...state,
                items: updatedCartItems
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                items: state.items.filter(
                    item => item.id !== action.pid
                )
            }
        case EMPTY_CART:
            return {
                ...state,
                items: []
            }
        case RESTORE_CART:
            const cartItems = [];

            action.products.forEach(element => {
                cartItems.push(new CartItem(
                    element.id,
                    element.quantity,
                    element.packagingId,
                    element.name,
                    element.packagingName,
                    element.productNr,
                    element.currentPrice
                ));
            });

            return {
                ...state,
                items: cartItems
            }
        default:
            return state;
    }
};
