import React, { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    TextInput,
    StatusBar,
    Image,
    Alert,
    Text,
    TouchableOpacity,
    KeyboardAvoidingView,
    Platform
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';

import * as authActions from '../store/actions/auth';
import { Colors } from '../constants/Colors';
import HideKeyboard from '../components/HideKeyboard';
import CustomModal from '../components/CustomModal';

const AuthScreenContainer = () => {
    if (Platform.OS === 'web') {
        return (
            <View style={styles.screen}>
                <AuthScreen />
            </View>

        )
    } else {
        return (
            <HideKeyboard>
                <KeyboardAvoidingView style={styles.screen} behavior='height' keyboardVerticalOffset={-100}>
                    <AuthScreen />
                </KeyboardAvoidingView>
            </HideKeyboard>
        )
    }

}




const AuthScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch();

    let myBackground = {};
    if (isDisabled) {
        myBackground = { backgroundColor: 'lightgrey' };
    }


    const authHandler = async () => {
        try {
            await dispatch(authActions.login(username, password))
        } catch (err) {
            Alert.alert(err.message);
        }
    };

    useEffect(() => {
        if (username.length > 0 && password.length > 0) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [username, password]);


    return (


        <React.Fragment>
            <CustomModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
            <Image
                style={styles.logo}
                source={require('../assets/logo/Logo_webbill.png')}
            />
            <View style={styles.form}>
                <TextInput
                    style={styles.inputField}
                    value={username}
                    onChangeText={setUsername}
                    autoCapitalize='none'
                    autoCorrect={false}
                    keyboardType='email-address'
                    placeholder='Benutzername'
                />
                <TextInput
                    style={styles.inputField}
                    value={password}
                    onChangeText={setPassword}
                    autoCapitalize='none'
                    autoCorrect={false}
                    keyboardType='default'
                    secureTextEntry={true}
                    placeholder='Passwort'
                />
                <TouchableOpacity style={[styles.button, myBackground]} onPress={authHandler} disabled={isDisabled}>
                    <Text style={styles.buttonTitle}>Einloggen</Text>
                    <Ionicons name="ios-log-in-outline" size={24} color="white" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setModalVisible(true)} style={{ alignItems: 'center' }}>
                    <Text style={styles.link}>Registrieren</Text>
                </TouchableOpacity>


            </View>
            <View style={styles.buildNumberContainer}>
                <Text style={styles.buildNumber}>1.0.1</Text>
            </View>

        </React.Fragment>

    );
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: StatusBar.currentHeight,
        backgroundColor: 'white'
    },
    form: {
        alignContent: 'center',
        top: -50,
        width: '70%',
        maxWidth: 300
    },
    inputField: {
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 12,
        height: 40,
        paddingHorizontal: 13,
        marginBottom: 15,
    },
    inputLabel: {
        marginLeft: 10,
    },
    button: {
        backgroundColor: Colors.primary,
        height: 40,
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 10,
        color: 'white'
    },
    logo: {
        width: 237.6,
        height: 159.6,
        top: -80
    },
    buildNumberContainer: {
        position: 'absolute',
        bottom: 20,
        right: 20
    },
    buildNumber: {
        color: 'grey'
    },
    link: {
        marginTop: 12,
        color: Colors.primary
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
        paddingHorizontal: 15
    },
    modalView: {
        flex: 1,
        width: '100%',
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
});

export default AuthScreenContainer;
