import React from "react";
import { View, Text, TouchableOpacity } from "react-native";

import { Colors } from "../../constants/Colors";


const PackingsChip = (props) => {

    let myBackground = {};
    let myTextColor = { color: 'black' };

    if (props.selected) {
        myBackground = { backgroundColor: Colors.primary };
        myTextColor = { color: 'white' };
    }

    return (
        <TouchableOpacity onPress={props.onPress} style={{ paddingRight: 5, paddingTop: 5, paddingBottom: 15 }}>
            <View style={[{ paddingHorizontal: 15, paddingVertical: 1, borderRadius: 30, borderColor: Colors.primary, borderWidth: 1 }, myBackground]}>
                <Text style={myTextColor}>
                    {props.title}
                </Text>
            </View>
        </TouchableOpacity>

    );
}

export default PackingsChip;