import React from 'react';
import { StyleSheet } from 'react-native';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { RootSiblingParent } from 'react-native-root-siblings';
import Portal from '@burstware/react-native-portal'

import AppNavigation from './navigation/AppNavigation';
import productReducer from './store/reducers/product';
import cardReducer from './store/reducers/cart';
import orderReducer from './store/reducers/order';
import authReducer from './store/reducers/auth';
import { OtaUpdater } from './components/OtaUpdater';

import {
  // en,
  // nl,
  // de,
  // pl,
  // pt,
  enGB,
  registerTranslation,
} from 'react-native-paper-dates'
// registerTranslation('en', en)
// registerTranslation('nl', nl)
// registerTranslation('pl', pl)
// registerTranslation('pt', pt)
// registerTranslation('de', de)
registerTranslation('en-GB', enGB)


const rootReducer = combineReducers({
  product: productReducer,
  cart: cardReducer,
  order: orderReducer,
  auth: authReducer
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));




export default function App() {
  return (
    <Provider store={store}>
      <Portal.Host>
        <RootSiblingParent>
          <AppNavigation />
          <OtaUpdater />
        </RootSiblingParent>
      </Portal.Host>
    </Provider>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
