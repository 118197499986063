class Product {
    constructor(id, name, productGroup, nr, packagingOnly, vp1, vp1_ID, vp2, vp2_ID, weightOnly, nameExt, packagings, orderQuantity, selectedPackaging, isFavourite, vk1, vk2, vk3, vkGew, onHotlist, currentPrice) {
        this.id = id;
        this.name = name;
        this.productGroup = productGroup;
        this.nr = nr;
        this.packagingOnly = packagingOnly;
        this.vp1 = vp1;
        this.vp1_ID = vp1_ID;
        this.vp2 = vp2;
        this.vp2_ID = vp2_ID;
        this.weightOnly = weightOnly;
        this.nameExt = nameExt;
        this.packagings = packagings;
        this.orderQuantity = orderQuantity;
        this.selectedPackaging = selectedPackaging;
        this.isFavourite = isFavourite;
        this.vk1 = vk1;
        this.vk2 = vk2;
        this.vk3 = vk3;
        this.vkGew = vkGew;
        this.onHotlist = onHotlist;
        this.currentPrice = currentPrice;
    }
}

export default Product