import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  StatusBar,
  Alert
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Colors } from '../constants/Colors';
import * as authActions from '../store/actions/auth';


const SettingsScreen = () => {
  const username = useSelector(state => state.auth.username)
  const dispatch = useDispatch();

  const clearDataAlert = () =>
    Alert.alert('Löschen', 'Möchten Sie unwiderruflich alle gespeicherten Favoriten und Bestellungen löschen?', [
      {
        text: 'Nein',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      { text: 'Ja', onPress: () => clearAll(), style: 'destructive', },
    ]);

  const clearAll = async () => {
    try {
      await AsyncStorage.clear()
    } catch (e) {
      console.log('async clear error:', e);
    }

    console.log('Done.')
  }

  return (
    <View style={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
      <View style={styles.container}>
        <View style={styles.divider} >
          <Text style={styles.label}>App Version</Text>
          <Text style={styles.label}>1.0.1</Text>
        </View>
        <View style={styles.divider} >
          <Text style={styles.label}>User</Text>
          <Text style={styles.label}>{username}</Text>
        </View>
        <View style={styles.divider} >
          <TouchableOpacity style={styles.listButton} onPress={() => { clearDataAlert() }}>
            <Text style={styles.listButtonTitle}>Lokale Daten zurücksetzen</Text>
          </TouchableOpacity>
        </View>
      </View>


      <TouchableOpacity style={styles.button} onPress={() => {
        dispatch(authActions.logout());
      }}>
        <Text style={styles.buttonTitle}>Logout</Text>
        <Ionicons name="ios-log-out-outline" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    paddingBottom: 15,
    justifyContent: 'space-between',
    paddingTop: StatusBar.currentHeight
  },
  label: {
    fontSize: 18,
  },
  divider: {
    width: "95%",
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  button: {
    marginTop: 30,
    backgroundColor: Colors.primary,
    padding: 8,
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  buttonTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 10,
    color: 'white'
  },
  container: {
    width: "100%",
    flexDirection: 'column',
    alignItems: 'center'
  },
  listButton: {

  },
  listButtonTitle: {
    color: 'red',
    fontSize: 18,
  }
});

export default SettingsScreen;
