class CartItem {
    constructor(id, quantity, packagingId, name, packagingName, productNr, currentPrice) {
        this.id = id;
        this.quantity = quantity;
        this.packagingId = packagingId;
        this.name = name;
        this.packagingName = packagingName;
        this.productNr = productNr;
        this.currentPrice = currentPrice;
    }
}

export default CartItem;
