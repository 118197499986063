import AsyncStorage from '@react-native-async-storage/async-storage';

import Config from '../../Config';

import CartItem from '../../classes/cart-item';
export const ORDER_CART = 'ORDER_CART';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';

export const orderCart = (deliveryDate, deliveryNote) => {
    return async (dispatch, getState) => {
        const cartItems = getState().cart.items;
        const orderItems = [];
        const historyItems = [];
        cartItems.forEach(el => orderItems.push({
            product_id: el.id,
            amount: el.quantity,
            packing_id: el.packagingId,
            weight_position: (el.packagingId === 0) ? true : false,
        }));

        cartItems.forEach(el => historyItems.push(
            new CartItem(
                el.id,
                el.quantity,
                el.packagingId,
                el.name,
                el.packagingName,
                el.productNr
            )
        ));

        try {
            let weightPosition = false;
            const { clientId, accountId } = getState().auth;

            const response = await fetch(
                `${Config.apiUrl}/rest/webbill/sendOrderService/sendOrder`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        account_id: accountId,
                        client_id: clientId,
                        delivery_date: deliveryDate.getTime(),
                        notice: deliveryNote,
                        orderitems: orderItems
                    })
                }
            );


            if (response.status !== 200) {
                let message = 'Fehler beim Senden der Daten. Bitte versuchen sie es erneut.';
                throw new Error(message);
            }

            const resData = await response.text();

            if (resData !== 'OKAY') {
                throw new Error(resData);
            }

            dispatch({
                type: ORDER_CART,
                cartItems
            })
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export const setHistory = (orderHistory) => {
    return dispatch => {
        //dispatch(setLogoutTimer(expiryTime));
        dispatch({ type: SET_ORDER_HISTORY, orderHistory });
    };
};



export const saveOrdersToStorage = () => {
    return async (dispatch, getState) => {
        const orders = getState().order.orders;
        const currentUser = getState().auth.clientId;

        AsyncStorage.setItem(
            'historyOrders-' + currentUser,
            JSON.stringify({
                orders
            })
        );
    }
};

