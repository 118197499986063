import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';

import OrderStackNavigator from './OrderStackNavigator';
import CartStackNavigator from './CartStackNavigator';
import HistoryStackNavigator from './HistoryStackNavigator';
import SettingsStackNavigator from './SettingsStackNavigator';
import AuthScreen from '../screens/AuthScreen';

import { Colors } from '../constants/Colors';



const Tab = createBottomTabNavigator();

const screenOptions = (route, color) => {
  let iconName;

  switch (route.name) {
    case 'OrderStack':
      iconName = 'ios-list';
      break;
    case 'HistoryStack':
      iconName = 'ios-refresh';
      break;
    case 'CartStack':
      iconName = 'ios-cart';
      break;
    case 'SettingsStack':
      iconName = 'ios-settings';
      break;
    default:
      break;
  }

  return <Ionicons name={iconName} color={color} size={26} />;
};

export const BottomNavigator = () => {
  const cartItems = useSelector(state => state.cart.items);
  const products = useSelector(state => state.product.products);
  const username = useSelector(state => state.auth.username)
  const hideButtons = products.length > 0 ? false : true;

  let hideButton = {};
  if (hideButtons) {
    hideButton = { tabBarButton: () => null }
  };


  return (
    <Tab.Navigator

      screenOptions={({ route }) => (
        {
          tabBarIcon: ({ color }) => screenOptions(route, color),
          headerShown: false,
          tabBarActiveTintColor: Colors.primary

        })}
    >
      <Tab.Screen name="OrderStack" component={OrderStackNavigator} options={{ tabBarLabel: 'Bestellung', color: 'black' }} />
      <Tab.Screen name="HistoryStack" component={HistoryStackNavigator} options={{
        ...{
          tabBarLabel: 'Verlauf',
        },
        ...hideButton
      }} />
      <Tab.Screen name="CartStack" component={CartStackNavigator} options={{ ...{ tabBarLabel: 'Warenkorb', tabBarBadge: cartItems.length }, ...hideButton }} />
      <Tab.Screen name="SettingsStack" component={SettingsStackNavigator} options={{ tabBarLabel: username }} />
    </Tab.Navigator>
  );
}



const AuthStackNavigator = createNativeStackNavigator();

export const AuthNavigator = () => {
  return (
    <AuthStackNavigator.Navigator>
      <AuthStackNavigator.Screen
        name="Auth"
        component={AuthScreen}
        options={{
          headerShown: false
        }}
      />
    </AuthStackNavigator.Navigator>
  );
};














