import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { View, FlatList, StyleSheet, TouchableOpacity, Platform } from "react-native";
import { useSelector } from 'react-redux';
import { Ionicons, SimpleLineIcons, Fontisto } from '@expo/vector-icons';
import { SearchBar } from 'react-native-elements';

import ProductItem from "../components/product/ProductItem";



const Products = (props) => {
  const products = useSelector(state => state.product.products);
  const selGroupProducts = products.filter(el => el.productGroup === props.route.params.title);
  let productsData = [];
  if (props.route.params.title === 'Alle Produkte') {
    productsData = products;
  } else if (props.route.params.title === 'Favoriten') {
    productsData = products.filter(el => el.isFavourite === true);
  } else if (props.route.params.title === 'Hotlist') {
    productsData = products.filter(el => el.onHotlist === true);
  } else {
    productsData = products.filter(el => el.productGroup === props.route.params.title);
  }

  const [filteredDataSource, setFilteredDataSource] = useState(productsData);
  const [masterDataSource, setMasterDataSource] = useState(productsData);
  const [isSearchVisible, setSearchVisible] = useState((Platform.OS === 'web') ? true : false);
  const [isOnlyFavourites, setOnlyFavourites] = useState(false);
  const [onlyHotlist, setOnlyHotlist] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')


  useLayoutEffect(() => {
    props.navigation.setOptions({
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={() => setSearchVisible(state => !state)} style={{ padding: 5 }}>
            <Ionicons name="ios-search" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { setOnlyFavourites(!isOnlyFavourites) }} style={{ padding: 5 }}>
            <Ionicons name={isOnlyFavourites ? "ios-heart-sharp" : "ios-heart-outline"} size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { setOnlyHotlist(!onlyHotlist) }} style={{ padding: 5 }}>
            {onlyHotlist ? <Fontisto name={"fire"} size={24} color="black" /> : <SimpleLineIcons name={"fire"} size={22} color="black" />}
          </TouchableOpacity>
        </View>

      ),
    });
  }, [props.navigation, setSearchVisible, isOnlyFavourites, onlyHotlist]);


  useEffect(() => {
    const allProducts = masterDataSource;
    let filteredProducts = [];

    if (onlyHotlist && isOnlyFavourites) {
      filteredProducts = masterDataSource.filter(el => el.onHotlist === true && el.isFavourite === true);
    } else if (onlyHotlist) {
      filteredProducts = masterDataSource.filter(el => el.onHotlist === true);
    } else if (isOnlyFavourites) {
      filteredProducts = masterDataSource.filter(el => el.isFavourite === true);
    } else {
      filteredProducts = allProducts
    }
    setFilteredDataSource(filteredProducts);

  }, [onlyHotlist, isOnlyFavourites]);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        const newData = masterDataSource.filter(function (item) {
          const itemData = item.name
            ? item.name.toUpperCase()
            : ''.toUpperCase();
          const textData = searchTerm.toUpperCase();
          if (itemData.indexOf(textData) > -1) return true;
          if (item.nr.toString().indexOf(searchTerm) > -1) return true;
          return false
        });
        setFilteredDataSource(newData);
      } else {
        setFilteredDataSource(masterDataSource);
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const onRefreshHandler = useCallback(() => {
    setSearchVisible(true);
  }, []);


  const renderItem = ({ item }) => {

    return (<ProductItem
      name={item.name}
      packagings={item.packagings}
      productNr={item.nr}
      id={item.id}
      quantity={item.orderQuantity.toString()}
      selectedPackaging={item.selectedPackaging}
      isFavourite={item.isFavourite}
      onHotlist={item.onHotlist}
      currentPrice={item.currentPrice}
      nameExt={item.nameExt}
    />)
  };

  return (
    <View style={{ flex: 1 }}>
      {isSearchVisible ? <SearchBar
        round
        searchIcon={{ size: 18 }}
        onChangeText={setSearchTerm}
        onClear={() => setSearchTerm('')}
        placeholder="Artikel eingeben..."
        value={searchTerm}
        lightTheme={true}
        containerStyle={{ backgroundColor: 'rgb(243,243,243)' }}
        inputContainerStyle={{ backgroundColor: 'white' }}
      /> : null}
      <FlatList
        data={filteredDataSource}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        onRefresh={onRefreshHandler}
        refreshing={false}
      />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});



export default Products;