import React, { useRef, useState, useEffect } from 'react';
import { Button, StyleSheet, View, Text } from 'react-native';
import Portal from '@burstware/react-native-portal'
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import * as Updates from 'expo-updates';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colors } from '../constants/Colors';


const INTERVAL_RENDER = 1000 * (Updates.releaseChannel.startsWith('dev') ? 10 : 60)


const INTERVAL_OTA_CHECK = 1000 * 60 * (Updates.releaseChannel.startsWith('dev') ? 1 : 15)


async function checkForUpdates() {
    const update = await Updates.checkForUpdateAsync()
    if (!update.isAvailable) {
        throw new Error('No updates available')
    }

    const result = await Updates.fetchUpdateAsync()
    if (!result.isNew) {
        throw new Error('Fetched update is not new')
    }

    return true
}

const UpdateScreen = () => {
    return (
        <Portal>
            <View style={styles.container}>
                <MaterialCommunityIcons name="cloud-refresh" size={64} color={Colors.primary} />
                <View style={styles.textContainer}>
                    <Text style={styles.text}>Ein neues Update steht zur Verfügung.</Text>
                    <Text style={styles.text}>Bitte App neu starten.</Text>
                </View>
                <Button color={Colors.primary} title='Neustart' onPress={() => { Updates.reloadAsync() }} />
            </View>
        </Portal>
    )
}



export function OtaUpdater() {
    const now = useInaccurateTimestamp({ every: INTERVAL_RENDER });
    const isMounted = useRef(true)
    const [updateIsAvailable, setUpdateAvailable] = useState(false);


    const lastUpdate = useRef(0)

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (updateIsAvailable) {
            return
        }

        if (now - lastUpdate.current < INTERVAL_OTA_CHECK) {
            return
        }

        lastUpdate.current = now

        checkForUpdates()
            .then(() => { isMounted.current && setUpdateAvailable(true) })
            .catch((_reason) => { })
    }, [now])


    if (updateIsAvailable) {
        return <UpdateScreen />
    } else {
        return null
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.95)'
    },
    textContainer: {
        alignItems: 'center',
        marginVertical: 10
    }
});