export const ADD_ITEM_TO_CART = 'SET_ITEM_COUNT_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_ITEM_FORM_CART = 'UPDATE_ITEM_FORM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const RESTORE_CART = 'RESTORE_CART';

export const addItemToCart = (id, quantity, packagingId, name, packagingName, productNr, currentPrice) => {
    return {
        type: ADD_ITEM_TO_CART,
        product: {
            id,
            quantity,
            packagingId,
            name,
            packagingName,
            productNr,
            currentPrice
        }
    }
};

export const updateItemFromCart = (id, quantity, packagingId, name, packagingName, productNr, currentPrice) => {

    return {
        type: UPDATE_ITEM_FORM_CART,
        product: {
            id,
            quantity,
            packagingId,
            name,
            packagingName,
            productNr,
            currentPrice
        }
    };
}

export const removeItem = productId => {
    return { type: REMOVE_FROM_CART, pid: productId };
};


export const emptyCart = () => {
    return { type: EMPTY_CART };
};

export const restoreCart = (products) => {
    return { type: RESTORE_CART, products };
}