import AsyncStorage from '@react-native-async-storage/async-storage';

import Config from '../../Config';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_DID_TRY_AL = 'SET_DID_TRY_AL';





export const setDidTryAL = () => {
    return { type: SET_DID_TRY_AL };
};


export const authenticate = (accountId, clientId, expirationTime, username) => {
    return dispatch => {
        //dispatch(setLogoutTimer(expiryTime));
        dispatch({ type: LOGIN, accountId, clientId, expirationTime, username });
    };
};

// TODO Encode username/password to URL

export const login = (username, password) => {
    return async dispatch => {
        try {
            const user = encodeURIComponent(username);
            const pw = encodeURIComponent(password);
            console.log(user, pw);
            const response = await fetch(
                `${Config.apiUrl}/rest/webbill/loginService/${user}::${pw}`,
                {
                    method: 'GET',
                }
            );

            console.log('====================================');
            console.log("test");
            console.log('====================================');


            if (response.status !== 200) {
                let message = 'Fehler beim Abrufen der Daten. Bitte versuchen sie es erneut';
                console.log("error not 200");
                throw new Error(message);
            }

            const resData = await response.json();


            if (resData.STATUS !== 100) {
                console.log("error not 100");
                console.log(resData);
                throw new Error(resData.TEXT);
            }

            console.log('====================================');
            console.log(resData);
            console.log('====================================');


            const expirationDate = new Date(
                new Date().getTime() + 168 * 3600 * 1000
            );


            dispatch(
                {
                    type: LOGIN,
                    accountId: resData.ACCOUNT_ID,
                    clientId: resData.CLIENT_ID,
                    expirationDate: expirationDate,
                    username
                }
            );

            saveDataToStorage(
                resData.ACCOUNT_ID,
                resData.CLIENT_ID,
                expirationDate,
                username
            );

        } catch (err) {
            throw new Error(err.message);
        }

    }
};

export const logout = () => {
    AsyncStorage.removeItem('userData');
    return { type: LOGOUT };
}


const saveDataToStorage = (accountId, clientId, expirationDate, username) => {
    AsyncStorage.setItem(
        'userData',
        JSON.stringify({
            accountId: accountId,
            clientId: clientId,
            expirationDate: expirationDate,
            username
            //expiryDate: expirationDate.toISOString()
        })
    );
};
