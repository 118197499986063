import React from 'react';
import { useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';

import { BottomNavigator, AuthNavigator } from './MasterNavigator';
import StartupScreen from '../screens/StartupScreen';


const AppNavigator = () => {
    const isAuth = useSelector(state => !!state.auth.clientId);
    const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);

    return (
        <NavigationContainer>
            {isAuth && <BottomNavigator />}
            {!isAuth && didTryAutoLogin && <AuthNavigator />}
            {!isAuth && !didTryAutoLogin && <StartupScreen />}
        </NavigationContainer>
    );
};

export default AppNavigator;
