import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import Settings from '../screens/Settings';



const Stack = createNativeStackNavigator();

const SettingsStackNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{ title: 'Einstellungen' }}
      />
    </Stack.Navigator>
  );
}

export default SettingsStackNavigator;