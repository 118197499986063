import React, { useState } from "react";
import { ActivityIndicator, View, Text, StyleSheet, TouchableOpacity, TextInput, Alert, Platform } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import DateTimePicker from '@react-native-community/datetimepicker';
import { CommonActions } from '@react-navigation/native';
import Toast from 'react-native-root-toast';
import moment from 'moment';

import * as orderActions from '../store/actions/order';
import * as cartActions from '../store/actions/cart';
import * as productActions from '../store/actions/product';

import { Colors } from "../constants/Colors";
import HideKeyboard from "../components/HideKeyboard";
import WebDatePicker from "../components/WebDatePicker";
import { DatePickerInput } from 'react-native-paper-dates';




const toast = () => {
  Toast.show('Bestellung erfolgreich abgeschickt', {
    duration: Toast.durations.LONG,
    position: Toast.positions.BOTTOM,
    shadow: true,
    animation: true,
    hideOnPress: true,
    delay: 0
  });
}

const OrderContainer = (props) => {
  if (Platform.OS === 'web') {
    return (
      <Order props={props} />
    )
  } else {
    return (
      <HideKeyboard>
        <Order props={props} />
      </HideKeyboard>
    )
  }
}



const Order = (props) => {
  const cartItems = useSelector(state => state.cart.items);
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [date, setDate] = useState(tomorrow);
  const [show, setShow] = useState(false);
  const [orderNote, setOrderNote] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputDate, setInputDate] = useState(undefined);

  const dispatch = useDispatch();

  const orderCart = async () => {
    try {
      setIsDisabled(true);
      await dispatch(orderActions.orderCart(date, orderNote));
      await dispatch(orderActions.saveOrdersToStorage());
      await dispatch(cartActions.emptyCart());
      await dispatch(productActions.fetchProducts());
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'OrderStack' },
          ],
        })
      );
      toast();
    } catch (err) {
      setIsDisabled(false);
      Alert.alert(err.message);
    }
  };

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(false);
    setDate(currentDate);
  };

  const onInput = (prop) => {
    console.log("-----------------");
    console.log(prop);
  }


  return (
    <View style={styles.container}>
      <TextInput
        style={styles.orderDescription}
        multiline={true}
        placeholder='Geben Sie hier zusätzliche Informationen ein'
        value={orderNote}
        onChangeText={setOrderNote}
      />
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text style={styles.title}>Lieferdatum:</Text>
        {Platform.OS === 'android' && <Text style={styles.date} onPress={() => { setShow(true) }}>{moment(date).format("DD.MM.YYYY")}</Text>}
        {Platform.OS === 'ios' && <View style={{ flex: 1, top: 9 }}>
          <DateTimePicker
            value={date}
            mode='date'
            display="compact"
            onChange={onChange}
          />
        </View>}
        {Platform.OS === 'web' && <View style={{ flex: 1, top: 9 }}>

          <DatePickerInput
            //TODO: make own version of DatePickerInput without paper library dependency
            locale="en"
            //label="Lieferdatum"
            value={inputDate}
            onChange={(d) => setInputDate(d)}
            inputMode="start"
          // mode="outlined" (see react-native-paper docs)
          // other react native TextInput props
          />
        </View>}
        {Platform.OS === 'android' && show && <DateTimePicker
          value={date}
          mode='date'
          display="calendar"
          onChange={onChange}
        />}
      </View>

      <TouchableOpacity style={styles.button} onPress={orderCart} disabled={isDisabled}>
        <Text style={styles.buttonTitle}>
          Bestellung abschicken
        </Text>

        {isDisabled && <ActivityIndicator color='white' size='small' /> || <Ionicons name="ios-paper-plane-outline" size={24} color="white" />}
      </TouchableOpacity>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20
  },
  orderDescription: {
    height: 200,
    backgroundColor: 'white',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 10
  },
  date: {
    fontSize: 16,
    marginTop: 30,
    marginBottom: 10,
    backgroundColor: '#d5d5d5',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 7
  },
  button: {
    marginTop: 30,
    backgroundColor: Colors.primary,
    padding: 15,
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 55
  },
  buttonTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 10,
    color: 'white'
  }
});



export default OrderContainer;