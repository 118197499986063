
import React, { useEffect, useRef, ReactElement } from 'react'
import { TextInput } from 'react-native'
import { format } from 'date-fns'


function WebDatePicker(props) {
    const { minimumDate, value, onChange, ...moreProps } = props

    const inputRef = useRef(null)

    console.log('====================================');
    console.log(value);
    console.log('====================================');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setNativeProps({
                type: 'date',
                min: format(minimumDate, 'yyyy-MM-dd'),
                pattern: 'd{4}-d{2}-d{2}',
                value: format(value, 'yyyy-MM-dd'),
                //onInput: onChange
            })
        }
    }, [inputRef.current])

    return <TextInput ref={inputRef} type="date" onInput={onChange} />
}

export default WebDatePicker