import AsyncStorage from '@react-native-async-storage/async-storage';

import Product from "../../classes/product";
import Config from '../../Config';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY';
export const UPDATE_PRODUCT_PACKAGING = 'UPDATE_PRODUCT_PACKAGING';
export const UPDATE_PRODUCT_FAVOURITE = 'UPDATE_PRODUCT_FAVOURITE';


export const fetchProducts = () => {

    return async (dispatch, getState) => {
        const clientId = getState().auth.clientId;
        try {
            const response = await fetch(
                `${Config.apiUrl}/rest/webbill/productService/${clientId}`,
                {
                    method: 'GET',
                }
            );



            if (response.status !== 200) {
                let message = 'Fehler beim Abrufen der Daten. Bitte überprüfen Sie die Verbindung.';
                throw new Error(message);
            }



            const resData = await response.json();


            const loadedProducts = [];
            const productGroups = [
                { title: 'Alle Produkte', id: 0 },
                { title: 'Favoriten', id: 1 },
                { title: 'Hotlist', id: 2 }
            ];

            const favourites = await AsyncStorage.getItem('productFavourites');
            const tansformedFavourites = JSON.parse(favourites);
            const productFavourites = tansformedFavourites ? tansformedFavourites : [];

            for (const key in resData) {
                const randomNum = Math.random();
                const newProduct = new Product(
                    resData[key].ID,
                    resData[key].BEZ,
                    resData[key].ART_GR,
                    resData[key].NR,
                    resData[key].PACKING_ONLY,
                    resData[key].VP1,
                    resData[key].VP1_ID,
                    resData[key].VP2,
                    resData[key].VP2_ID,
                    resData[key].WEIGHT_ONLY,
                    resData[key].BEZ_EXT,
                    [],
                    0,
                    null,
                    (productFavourites.indexOf(resData[key].ID) !== -1) ? true : false,
                    resData[key].VK1,
                    resData[key].VK2,
                    resData[key].VK3,
                    resData[key].VKGEW,
                    resData[key].ON_HOTLIST,

                );
                const packagings = []
                if (newProduct.weightOnly === true) {
                    packagings.push({
                        id: 0,
                        name: 'KG',
                        price: newProduct.vkGew
                    })
                    newProduct.selectedPackaging = 0;
                    newProduct.currentPrice = newProduct.vkGew;
                } else {
                    if (newProduct.vp1 !== '---') {
                        packagings.push({
                            id: newProduct.vp1_ID,
                            name: newProduct.vp1,
                            price: newProduct.vk1
                        })
                        if (!newProduct.selectedPackaging) {
                            newProduct.selectedPackaging = newProduct.vp1_ID;
                            newProduct.currentPrice = newProduct.vk1;
                        }
                    };
                    if (newProduct.vp2 !== '---') {
                        packagings.push({
                            id: newProduct.vp2_ID,
                            name: newProduct.vp2,
                            price: newProduct.vk2
                        })
                        if (!newProduct.selectedPackaging) {
                            newProduct.selectedPackaging = newProduct.vp2_ID;
                            newProduct.currentPrice = newProduct.vk2;
                        }
                    };
                    if (newProduct.packagingOnly === false) {
                        packagings.push({
                            id: 0,
                            name: 'KG',
                            price: newProduct.vkGew
                        })
                        if (!newProduct.selectedPackaging) {
                            newProduct.selectedPackaging = 0;
                            newProduct.currentPrice = newProduct.vkGew;
                        }
                    };
                };
                newProduct.packagings = packagings;





                loadedProducts.push(newProduct);

                if (productGroups.findIndex(item => item.title === resData[key].ART_GR) < 0) {
                    var myProductGroup = new Object();
                    myProductGroup.title = resData[key].ART_GR
                    myProductGroup.id = productGroups.length
                    productGroups.push(myProductGroup)
                }

            }

            if (getState().cart.items.length > 0) {
                const cartItems = getState().cart.items;
                cartItems.forEach(element => {
                    const productIndex = loadedProducts.findIndex(prod => prod.id === element.id);
                    loadedProducts[productIndex].orderQuantity = element.quantity;
                    loadedProducts[productIndex].selectedPackaging = element.packagingId;
                    loadedProducts[productIndex].currentPrice = element.currentPrice;
                });
            }

            loadedProducts.sort((a, b) => (a.name > b.name) ? 1 : -1)







            dispatch({
                type: SET_PRODUCTS,
                products: loadedProducts,
                productGroups: productGroups
            })
        } catch (err) {
            throw new Error(err);
        }
    }
};

export const updateProductQuantity = (id, quantity) => {
    return { type: UPDATE_PRODUCT_QUANTITY, product: { id, quantity } };
}
export const updateProductPackaging = (id, packagingId, currentPrice) => {
    return { type: UPDATE_PRODUCT_PACKAGING, product: { id, packagingId, currentPrice } };
}
export const updateProductFavourite = (pid, isFavourite) => {
    return async (dispatch) => {
        const favourites = await AsyncStorage.getItem('productFavourites');
        const tansformedFavourites = JSON.parse(favourites);
        let productFavourites = tansformedFavourites ? tansformedFavourites : []

        if (isFavourite) {
            productFavourites.push(pid);
        } else {
            productFavourites = productFavourites.filter(el => el !== pid);
        }

        saveFavouriteToStorage(productFavourites);


        dispatch({ type: UPDATE_PRODUCT_FAVOURITE, pid, isFavourite })
    }

}

const saveFavouriteToStorage = (productFavourites) => {
    AsyncStorage.setItem(
        'productFavourites',
        JSON.stringify(productFavourites)
    );
};


