import React from 'react';
import { Button } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';


import Order from '../screens/Order';
import Cart from '../screens/Cart';



const Stack = createNativeStackNavigator();

const OrderStackNavigator = () => {
    const cartItems = useSelector(state => state.cart.items);
    const showButton = cartItems.length > 0 ? true : false;
    return (
        <Stack.Navigator>
            <Stack.Screen
                name="Cart"
                component={Cart}
                options={({ navigation }) => ({
                    title: 'Warenkorb',
                    headerRight: () => {
                        if (showButton) {
                            return (
                                <Button
                                    onPress={() => navigation.push('Bestellung')}
                                    title="Bestellen"
                                />
                            )
                        }
                    }
                })}
            />
            <Stack.Screen
                name="Bestellung"
                component={Order}
            />
        </Stack.Navigator>
    );
}

export default OrderStackNavigator;