import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import ProductsGroups from '../screens/ProductsGroups';
import ProductsGroup from '../screens/Products';


const Stack = createNativeStackNavigator();

const OrderStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false
      }}
    >
      <Stack.Screen
        name="ProductsGroups"
        component={ProductsGroups}
        options={{ title: 'Produktgruppen' }}
      />
      <Stack.Screen
        name="ProductsGroup"
        component={ProductsGroup}
        options={({ route }) => ({ title: route.params.title })}
      />
    </Stack.Navigator>
  );
}

export default OrderStackNavigator;