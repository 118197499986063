import React from "react";
import { View, Text, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { useDispatch } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import Toast from 'react-native-root-toast';
import { CommonActions } from '@react-navigation/native';

import * as cartActions from '../store/actions/cart';
import * as productActions from '../store/actions/product';

import { Colors } from "../constants/Colors";




const toast = () => {
  Toast.show('Bestellung wurde wiederhergestellt', {
    duration: Toast.durations.LONG,
    position: Toast.positions.BOTTOM,
    shadow: true,
    animation: true,
    hideOnPress: true,
    delay: 100
  });
}





const Products = (props) => {
  const orderItems = props.route.params.items;

  const dispatch = useDispatch();


  const renderItem = ({ item }) => {
    return (
      <View style={styles.itemContainer}>
        <View style={styles.itemContainerLeft}>
          <Text
            style={styles.itemTitle}
            numberOfLines={2}
          >{item.name}</Text>
          <Text>Art. Nr.: {item.productNr}</Text>
        </View>
        <Text style={styles.itemQuantity}>{item.quantity} {item.packagingName}</Text>
      </View>
    )
  };

  const restoreHandler = async () => {
    await dispatch(cartActions.restoreCart(orderItems));
    await dispatch(productActions.fetchProducts());
    props.navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: 'HistoryStack' },
        ],
      })
    );
    toast();
  }

  return (
    <View style={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
      <FlatList
        data={orderItems}
        renderItem={renderItem}
        keyExtractor={item => item.id}
      />
      <TouchableOpacity style={styles.button} onPress={restoreHandler}>
        <Text style={styles.buttonTitle}>
          Bestellung wiederherstellen
        </Text>
        <Ionicons name="ios-refresh" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomColor: 'lightgrey',
    borderBottomWidth: 1,
    flex: 1
  },
  itemTitle: {
    fontSize: 18,
    //fontWeight: 'bold'
  },
  itemQuantity: {
    fontSize: 18,
    marginLeft: 5,
    //fontWeight: 'bold'
  },
  itemContainerLeft: {
    flex: 1
  },
  button: {
    marginTop: 30,
    backgroundColor: Colors.primary,
    padding: 8,
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  buttonTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 10,
    color: 'white'
  }
});



export default Products;