//import { produce } from 'immer';
import { SET_PRODUCTS, UPDATE_PRODUCT_QUANTITY, UPDATE_PRODUCT_FAVOURITE, UPDATE_PRODUCT_PACKAGING } from '../actions/product';



const initialState = {
    products: [],
    productGroups: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                products: action.products,
                productGroups: action.productGroups
            };
        case UPDATE_PRODUCT_QUANTITY:
            const updatedProducts = [...state.products];
            const productIndex = updatedProducts.findIndex(el => el.id === action.product.id);

            updatedProducts[productIndex].orderQuantity = action.product.quantity;

            return {
                ...state,
                products: updatedProducts
            };
        case UPDATE_PRODUCT_PACKAGING:
            const updatedProductsPackage = [...state.products];
            const productPackageIndex = updatedProductsPackage.findIndex(el => el.id === action.product.id);

            updatedProductsPackage[productPackageIndex].selectedPackaging = action.product.packagingId;
            updatedProductsPackage[productPackageIndex].currentPrice = action.product.currentPrice;

            return {
                ...state,
                products: updatedProductsPackage
            };
        case UPDATE_PRODUCT_FAVOURITE:
            const updatedProductsFavourite = [...state.products];
            const productFavouriteIndex = updatedProductsFavourite.findIndex(el => el.id === action.pid);
            updatedProductsFavourite[productFavouriteIndex].isFavourite = action.isFavourite;

            return {
                ...state,
                products: updatedProductsFavourite
            };
        default:
            return state;
    }
};
