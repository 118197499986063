import * as Updates from 'expo-updates';
import { Platform } from 'react-native';

/* let Config = {
    apiUrl: (Platform.OS === "web") ? 'https://fathomless-eyrie-12717.herokuapp.com/https://v3.webbill.at' : 'https://v3.webbill.at',
};

if (Updates.releaseChannel === 'prod') {
    Config.apiUrl = (Platform.OS === "web") ? 'https://fathomless-eyrie-12717.herokuapp.com/https://v3.webbill.at' : 'https://v3.webbill.at';
} */

let Config = {
    apiUrl: 'https://v3.webbill.at',
};

if (Updates.releaseChannel === 'prod') {
    Config.apiUrl = 'https://v3.webbill.at';
}
export default Config;