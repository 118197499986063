import React, { useEffect } from 'react';
import {
    View,
    ActivityIndicator,
    StyleSheet,
} from 'react-native';
import { useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Colors } from '../constants/Colors';
import * as authActions from '../store/actions/auth';
import * as orderActions from '../store/actions/order';


const StartupScreen = props => {
    const dispatch = useDispatch();


    useEffect(() => {
        const loadOrderHistory = async (clientId) => {
            const historyOrders = await AsyncStorage.getItem('historyOrders-' + clientId);
            const transformedHistory = JSON.parse(historyOrders);
            dispatch(orderActions.setHistory((transformedHistory)));
        }

        const tryLogin = async () => {


            const userData = await AsyncStorage.getItem('userData');
            if (!userData) {
                dispatch(authActions.setDidTryAL());
                return;
            }
            const transformedData = JSON.parse(userData);
            const { accountId, clientId, expirationDate, username } = transformedData;


            if (expirationDate <= new Date() || !accountId || !clientId) {
                dispatch(authActions.setDidTryAL());
                return;
            }

            const expirationTime = expirationDate - new Date().getTime();
            loadOrderHistory(clientId);
            dispatch(authActions.authenticate(accountId, clientId, expirationTime, username));
        };


        tryLogin();
    }, [dispatch]);

    return (
        <View style={styles.screen}>
            <ActivityIndicator size="large" color={Colors.primary} />
        </View>
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default StartupScreen;
