import React, { useCallback } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { Ionicons, Fontisto } from '@expo/vector-icons';

import Packings from './Packings';
import OrderCount from './OrderCount';
import * as cartActions from '../../store/actions/cart';
import * as productActions from '../../store/actions/product';





const ProductItem = (props) => {

    const dispatch = useDispatch();



    const orderCountHandler = useCallback((count) => {
        let newCount = count.replace(',', '.');
        newCount = newCount.replace(/(^[0]*)|(^\.)/g, '');

        const oldCount = props.quantity;



        if (oldCount * 1 === 0 && newCount * 1 > 0) {
            const selectedPackagingName = props.packagings.find(el => el.id === props.selectedPackaging);
            dispatch(cartActions.addItemToCart(props.id, newCount, props.selectedPackaging, props.name, selectedPackagingName.name, props.productNr, selectedPackagingName.price));
            dispatch(productActions.updateProductQuantity(props.id, newCount));

        } else if (newCount * 1 === 0) {
            dispatch(cartActions.removeItem(props.id));
            dispatch(productActions.updateProductQuantity(props.id, 0));

        } else {
            const selectedPackagingName = props.packagings.find(el => el.id === props.selectedPackaging);
            dispatch(cartActions.updateItemFromCart(props.id, newCount, props.selectedPackaging, props.name, selectedPackagingName.name, props.productNr, selectedPackagingName.price));
            dispatch(productActions.updateProductQuantity(props.id, newCount));
        }

    })

    const add = useCallback(() => {
        let newCount = props.quantity * 1 + 1
        if (Math.floor(newCount) !== newCount) {
            newCount = newCount.toFixed(2);
        }
        orderCountHandler(newCount.toString());
    });

    const remove = useCallback(() => {
        let newCount;
        if (props.quantity < 1) {
            newCount = 0
        } else {
            newCount = props.quantity * 1 - 1
            if (Math.floor(newCount) !== newCount) {
                newCount = newCount.toFixed(2);
            }

        }
        orderCountHandler(newCount.toString());
    });


    const updateCartPackaging = useCallback((id) => {
        const selectedPackagingName = props.packagings.find(el => el.id === id);
        if (props.quantity * 1 > 0) {

            dispatch(cartActions.updateItemFromCart(props.id, props.quantity, id, props.name, selectedPackagingName.name, props.productNr, selectedPackagingName.price));
        }
        dispatch(productActions.updateProductPackaging(props.id, id, selectedPackagingName.price));
    });

    const favouriteHandler = useCallback(() => {
        dispatch(productActions.updateProductFavourite(props.id, !props.isFavourite));
    })

    return (<View style={styles.item}>
        <Text style={styles.title}
            numberOfLines={2}
        >{props.name}</Text>
        <View style={styles.containerLeft}>
            <View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingRight: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.subTitle}>{props.nameExt} / {props.productNr}</Text>
                        <TouchableOpacity onPress={favouriteHandler} style={{ marginRight: 5, padding: 7 }}>
                            {props.isFavourite ? <Ionicons name={"ios-heart-sharp"} size={22} color="red" /> : <Ionicons name={"ios-heart-outline"} size={22} color="grey" />}
                        </TouchableOpacity>
                        {props.onHotlist ? <Fontisto name={"fire"} size={20} color="orange" /> : null}
                    </View>
                    {false && <Text style={{ fontSize: 18 }}>
                        € {props.currentPrice}
                    </Text>}
                </View>
                <Packings
                    packingsList={props.packagings}
                    selectedPackagingId={updateCartPackaging}
                    packagingId={props.selectedPackaging}
                />
            </View>
            <OrderCount
                orderCount={props.quantity.toString()}
                setOrderCount={orderCountHandler}
                removeOne={remove}
                addOne={add}
                style={styles.containerRight}
            />
        </View>


    </View >)
};



const styles = StyleSheet.create({
    item: {
        borderBottomColor: 'lightgrey',
        borderBottomWidth: 1,
        paddingHorizontal: 10,
        paddingTop: 5,
        flexDirection: 'column',
        flex: 1
    },
    title: {
        fontSize: 18,
        marginBottom: 5
    },
    subTitle: {
        marginBottom: 5,
        marginRight: 5,
        minWidth: 97,
        paddingTop: 4
    },
    containerLeft: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
});

export default React.memo(ProductItem);
