import React from "react";
import { View, Text, FlatList, StyleSheet } from "react-native";
import { useSelector } from 'react-redux';

import ProductItem from "../components/product/ProductItem";





const Products = (props) => {
  const products = useSelector(state => state.product.products);



  const cartItems = useSelector(state => state.cart.items);



  const tranformedCartItems = cartItems.map(item => {
    return products.find(product => {
      if (item.id === product.id) {
        product.orderQuantity = item.quantity;
        product.selectedPackaging = item.packagingId;
        return product
      }
    })
  })



  const renderItem = ({ item }) => {
    return (<ProductItem
      name={item.name}
      packagings={item.packagings}
      productNr={item.nr}
      id={item.id}
      quantity={item.orderQuantity.toString()}
      selectedPackaging={item.selectedPackaging}
      isFavourite={item.isFavourite}
      onHotlist={item.onHotlist}
      currentPrice={item.currentPrice}
      nameExt={item.nameExt}
    />)
  };

  const listEmpty = () => {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: '70%' }}>
        <Text>
          Warenkorb ist leer
        </Text>
      </View>
    );
  };

  return (
    <View style={{ width: '100%' }}>
      <FlatList
        data={tranformedCartItems}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        ListEmptyComponent={listEmpty}
      />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});



export default Products;