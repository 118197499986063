import React from "react";
import { View, StyleSheet, TouchableOpacity, TextInput } from "react-native";
import { Ionicons } from '@expo/vector-icons';







const OrderCount = (props) => {




    return (<View style={styles.orderItem}>

        <View style={styles.orderCount}>
            <TextInput
                onChangeText={props.setOrderCount}
                value={(props.orderCount !== 'NaN') ? props.orderCount : '0'}
                style={styles.countText}
                keyboardType='decimal-pad'
            />
        </View>
        <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={props.removeOne}>
                <View style={[styles.orderButton, { borderBottomLeftRadius: 16 }]}>
                    <Ionicons name="ios-remove" size={24} color="black" />
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={props.addOne}>
                <View style={[styles.orderButton, { borderBottomRightRadius: 16 }]}>
                    <Ionicons name="ios-add" size={24} color="black" />
                </View>
            </TouchableOpacity>
        </View>
    </View>)
}


const styles = StyleSheet.create({
    orderItem: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    orderButton: {
        backgroundColor: 'lightgrey',
        paddingHorizontal: 15,
        paddingVertical: 5,
    },
    buttonContainer: {
        flexDirection: 'row'
    },
    orderCount: {
        borderColor: 'lightgrey',
        borderWidth: 2,
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
        width: 108,
        height: 34,
        alignItems: 'center',
        justifyContent: 'center'
    },
    countText: {
        fontSize: 18,
        width: '100%',
        textAlign: 'center'
    }
});

export default OrderCount
