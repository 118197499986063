import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import History from '../screens/History';
import HistoryOrder from '../screens/HistoryOrder';


const Stack = createNativeStackNavigator();

const OrderStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false
      }}
    >
      <Stack.Screen
        name="History"
        component={History}
        options={{ title: 'Verlauf' }}
      />
      <Stack.Screen
        name="HistoryOrder"
        component={HistoryOrder}
        options={({ route }) => (({ title: route.params.title }))}
      />
    </Stack.Navigator>
  );
}

export default OrderStackNavigator;